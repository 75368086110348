import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import index from "./algolia";
import textParser from "./textParser";
import Gallery from "./gallery";

class Modal extends Component {
  componentDidMount() {
    if (this.props.standalone) {
      document.body.style.backgroundColor = "rgba(45, 63, 80, 0.898)";
      this.getDataByDomain(this.props.standalone);
    } else {
      window.onpopstate = this.close;
    }
  }

  componentDidUpdate() {
    // dont perform query on first load when
    // we dont show any modal yet but component is mounted
    //
    if (
      this.props.pickedCompany &&
      this.state.companyData.objectID !== this.props.pickedCompany.objectID
    ) {
      this.getDataById(this.props.pickedCompany.objectID);
    }
  }

  close = () => {
    window.history.pushState(null, null, "/");
    document.body.style.overflow = "auto";

    this.props.close();
    this.setState({
      companyData: {},
      tab: 0
    });
  };

  getDataByDomain = domain => {
    index
      .search({
        query: domain,
        facetFilters: '[["domain:' + domain + '"]]',
        hitsPerPage: "1",
        page: "0",

        attributesToHighlight: []
      })
      .then(response => {
        if (response.hits.length > 0) {
          this.setState({
            companyData: response.hits[0]
          });
          document.title = response.hits[0].name;
        } else {
          document.body.innerHTML = `<center style="color:white;margin:25px">Nie ma takiej podstrony (${domain}) w autoinfo.pl</center>`;
        }
      });
  };

  getDataById = id => {
    if (this.props.pickedCompany) {
      document.body.style.overflow = "hidden";
      window.history.pushState(null, null);
    }

    index.getObject(id, (err, content) => {
      if (err) throw err;
      this.setState({
        companyData: content
      });
    });
  };

  state = {
    tab: 0,
    companyData: {}
  };

  render() {
    const { companyData } = this.state;
    const { pickedCompany } = this.props;
    const hasTabs =
      Array.isArray(companyData.tabs) && companyData.tabs.length > 0;
    return (
      <div
        className="background"
        onClick={event => {
          if (!this.props.close) return;
          event.preventDefault();
          if (event.target === event.currentTarget) {
            this.close();
          }
        }}
        style={
          this.props.standalone
            ? {
                position: "initial"
              }
            : {
                height: window.innerHeight,
                left: this.props.pickedCompany ? 0 : "100vw",
                backgroundColor: "rgba(45, 63, 80, 0.898)"
              }
        }
      >
        <div className="background__content">
          <h1 className="content__title">
            {(companyData && companyData.name) || ""}
          </h1>
          <h2 className="content__subtitle">
            {(companyData && companyData.shortDescription) || ""}
          </h2>

          {companyData ? (
            hasTabs ? (
              <React.Fragment>
                <div className="content__menu">
                  {companyData.tabs.map((tab, i) => (
                    <a
                      key={tab.name}
                      onClick={() => {
                        if (companyData.tabs[i].content) {
                          var elo = companyData.tabs[i].content.split(" ");
                          if (elo.length === 1) {
                            var win = window.open(
                              elo[0].substr(0, 7) === "http://" ||
                                elo[0].substr(0, 8) === "https://"
                                ? elo[0]
                                : `https://${elo[0]}`,
                              "_blank"
                            );
                            win.focus();
                          }
                        }
                        this.setState({
                          tab: i
                        });
                      }}
                      style={{
                        color: i === this.state.tab && "rgb(238, 138, 44)",

                        paddingRight: companyData.tabs.length - 1 === i && 18
                      }}
                      className="menu__item"
                    >
                      {tab.name}
                    </a>
                  ))}
                </div>
                <Slider pics={companyData.slider} />
                <div
                  style={{
                    padding: "9px 18px",
                    backgroundColor: "white"
                  }}
                >
                  {textParser(companyData.tabs[this.state.tab].content || "")}
                </div>
                <Gallery pics={companyData.tabs[this.state.tab].photos} />
              </React.Fragment>
            ) : (
              <Slider
                style={{
                  borderWidth: "1px 0px 0px",
                  borderStyle: "solid",
                  borderColor: "rgb(224, 224, 224)",
                  paddingBottom: "18px"
                }}
                pics={companyData.slider}
              />
            )
          ) : null}
          {companyData.contact && (
            <div
              style={{
                padding: "9px 18px",
                border: `0px solid #e0e0e0`,
                borderTopWidth: 1,
                backgroundColor: " rgb(248, 248, 248)"
              }}
            >
              {textParser(
                companyData.contact +
                  "\n" +
                  companyData.street +
                  "\n" +
                  companyData.postalCode +
                  " " +
                  companyData.city
              )}
            </div>
          )}
          {companyData && (
            <GoogleMap
              city={companyData.city || ""}
              street={companyData.street || ""}
            />
          )}
          <div className="content__prothesis" />
        </div>
      </div>
    );
  }
}

class GoogleMap extends React.PureComponent {
  render() {
    const { props } = this;
    return (
      <div
        style={{
          width: "100%",
          height: 400,
          backgroundColor: "rgb(242,242,242)"
        }}
        dangerouslySetInnerHTML={{
          __html: `<iframe
      width="100%"
      height="400"
      frameborder="0" style="border:0"
      src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDORhHoldLrm1oZPJEVWlvZNaSiTd6rgPc
        &q=${props.street || ``}+${props.city || `Poznań`}" allowfullscreen>
    </iframe>`
        }}
      />
    );
  }
}
class Slider extends Component {
  componentDidMount() {
    this.slide();
  }

  slide = () => {
    this.timeout = setTimeout(() => {
      var lol = this.props.pics.length - 1;

      this.setState(state => ({
        slider: lol === state.slider ? 0 : state.slider + 1
      }));

      this.slide();
    }, 2500);
  };

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }
  state = {
    slider: 0
  };
  render() {
    if (!this.props.pics || this.props.pics.length === 0) return null;

    return (
      <div style={this.props.style} className="content__slider">
        <img className="slider__img" src={this.props.pics[this.state.slider]} />
      </div>
    );
  }
}

export default Modal;
