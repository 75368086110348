import React from "react";

const parseContacts = toParse =>
  toParse &&
  toParse.split(" ").map((element, i) => {
    if (element.length > 8 && /^[\d ()+-]+$/.test(element)) {
      return (
        <a
          key={i}
          onClick={e => {
            var win = window.open(`tel:${element}`, "_self");
            e.preventDefault();
          }}
          href={`tel:${element}`}
        >
          {element}{" "}
        </a>
      );
    }

    if (
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        element
      )
    ) {
      return (
        <a
          key={i}
          onClick={e => {
            var win = window.open(`mailto:${element}`, "_self");
            e.preventDefault();
          }}
          href={`mailto:${element}`}
        >
          {element}{" "}
        </a>
      );
    }

    if (
      /[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(
        element
      )
    ) {
      const url =
        element.substr(0, 7) === "http://" ||
        element.substr(0, 8) === "https://"
          ? element
          : `http://${element}`;
      return (
        <a
          key={i}
          onClick={e => {
            var win = window.open(url, "_blank");
            win.focus();
            e.preventDefault();
          }}
          href={url}
        >
          {element}{" "}
        </a>
      );
    }
    return <React.Fragment key={i}>{element + " "}</React.Fragment>;
  });

export default text =>
  text.split("\n").map((item, i) => {
    return <p key={i}>{parseContacts(item)}</p>;
  });
