import React from "react";

export default class Gallery extends React.Component {
  state = { pickedPhoto: null };

  render() {
    let { pics } = this.props;
    if (pics && pics.length > 0) {
      return (
        <React.Fragment>
          <div style={this.props.style} className="gallery">
            {pics.map((e, i) => (
              <div
                key={i}
                onClick={() => this.setState({ pickedPhoto: i })}
                className="gallery__container"
              >
                <img className="container__pic" src={e} />
              </div>
            ))}
          </div>
          {this.state.pickedPhoto !== null && (
            <div
              onClick={() => this.setState({ pickedPhoto: null })}
              className="background background--black"
            >
              <img
                className="background__preview-pic"
                src={pics[this.state.pickedPhoto]}
              />
            </div>
          )}
        </React.Fragment>
      );
    }
    return null;
  }
}
