import React from "react";
import OurPage from "./ourPage";
import Header from "./header";
import smoothScroll from "./smoothScroll";
import index from "./algolia";
import getPosition from "./getPosition";
import Company from "./company";
import Map from "./map";
import Modal from "./modalPage";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.text = "";

    this.state = {
      results: null,
      animationOut: true,
      picked: null,
      renderAboutUs: false,
      aboutUsData: { tabs: [] },
      pickedTab: 0,
      moreRecords: "noMore" // hasMore || noMore || inProgress
    };
  }

  async componentDidMount() {
    try {
      await this.getAboutUsData();
      this.search();
      this.location = await getPosition();
    } catch (e) {
      Raven.captureException(e);
      console.log(e);
    } finally {
      if (this.location) this.search();
    }
  }

  getAboutUsData = async () => {
    try {
      const aboutUsData = (await (await fetch(
        "https://serwer1861309.home.pl/autoinfo/records.php"
      )).json()).filter(({ objectID }) => objectID == 1526894561776)[0];

      this.setState({
        aboutUsData,
        pickedTab:
          window.location.pathname === "/hot_cars" ? 2 : this.state.pickedTab
      });
    } catch (e) {
      console.warn(e);
    }
  };

  search = async (query = "") => {
    let params = {
      hitsPerPage: "500",
      page: "0",
      attributesToRetrieve: [
        "name",
        "shortDescription",
        "img",
        "sponsorship",
        "_geoloc"
      ],
      attributesToHighlight: []
    };

    if (this.location) {
      params = {
        ...params,
        aroundLatLng: `${this.location.coords.latitude},${
          this.location.coords.longitude
        }`
      };
    }
    smoothScroll();

    index.search(query, params, (err, content) =>
      err
        ? console.error(err.message)
        : this.setState({
            results: content.hits.filter(
              record => record.objectID !== `1526894561776`
            ),
            hasMore: content.nbPages - 1 === content.page ? "noMore" : "hasMore"
          })
    );
  };

  render() {
    return (
      <React.Fragment>
        <div>
          <Header
            dontShowSearchBar={this.state.pickedTab > 0}
            search={this.search}
            changeTab={index =>
              this.setState({ pickedTab: index }, smoothScroll)
            }
            tabs={[
              "Firmy",
              ...this.state.aboutUsData.tabs.map(tab => tab.name)
            ]}
            pickedTab={this.state.pickedTab}
          />
          {this.state.pickedTab > 0 ? (
            <OurPage
              data={this.state.aboutUsData.tabs[this.state.pickedTab - 1]}
              photos={
                this.state.aboutUsData.tabs[this.state.pickedTab - 1].photos
              }
            />
          ) : (
            <div style={{ paddingTop: 130 }}>
              <Map
                onPick={p => this.setState({ picked: p })}
                key="lol"
                results={this.state.results}
              />
              <div className="results">
                {this.state.results ? (
                  this.state.results.length === 0 ? (
                    <div className="results__no-results">
                      Nie odnaleziono pasującego rekordu
                    </div>
                  ) : (
                    this.state.results.map(data => (
                      <Company
                        key={data.objectID}
                        onPick={companyData =>
                          this.setState({ picked: companyData })
                        }
                        company={data}
                      />
                    ))
                  )
                ) : null}
              </div>
            </div>
          )}
        </div>
        <Modal
          pickedCompany={this.state.picked}
          close={id => this.setState({ picked: null })}
        />
      </React.Fragment>
    );
  }
}
