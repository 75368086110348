import React from "react";
import GoogleMapReact from "google-map-react";
import Pin from "./pin";

class Marker extends React.Component {
  render() {
    return (
      <a
        style={{
          cursor: "pointer",

          userSelect: "none",
          position: "absolute",
          width: 14,
          height: 20,
          left: -7,
          top: -10
        }}
        onMouseOver={this.props.onMouseOver}
        onMouseLeave={this.props.onMouseLeave}
        onClick={this.props.onClick}
      >
        <Pin
          onMouseOver={this.props.onMouseOver}
          onMouseLeave={this.props.onMouseLeave}
          onClick={this.props.onClick}
          className="marker"
        />
      </a>
    );
  }
}

export default class Map extends React.Component {
  static defaultProps = {
    results: false,
    onChange: e => e
  };

  state = {
    highlight: null
  };

  defaultCenter = {
    lat: 52.42,
    lng: 16.94
  };

  render() {
    return (
      <React.Fragment>
        <div style={{ height: "33vh" }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyBKjtrcHyObS_3i4Foe4fxwOnUrnB28TFg"
            }}
            onChange={e => this.props.onChange(e)}
            options={{
              fullscreenControl: false
            }}
            defaultCenter={this.defaultCenter}
            zoom={11}
          >
            {this.props.results &&
              this.props.results.map(
                result =>
                  result._geoloc && (
                    <Marker
                      key={result.objectID}
                      onClick={() => this.props.onPick(result)}
                      lat={result._geoloc.lat}
                      lng={result._geoloc.lng}
                      result={result}
                      onMouseOver={() => {
                        if (this.labelTimeout) clearTimeout(this.labelTimeout);
                        this.setState({ highlight: result.name });
                      }}
                      onMouseLeave={() =>
                        (this.labelTimeout = setTimeout(
                          () => this.setState({ highlight: null }),
                          1000
                        ))
                      }
                    />
                  )
              )}
          </GoogleMapReact>
        </div>
        {this.state.highlight && (
          <div className="marker__label">{this.state.highlight}</div>
        )}
      </React.Fragment>
    );
  }
}
