import React from "react";

export default function Cross(props) {
  return (
    <svg
      style={props.style}
      width={props.size || 26}
      height={props.size || 26}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.4551 4.24219L21.2129 0L12.7266 8.48633L4.24219 0.00195312L0 4.24414L8.48438 12.7285L0 21.2129L4.24219 25.4551L12.7266 16.9707L21.2129 25.457L25.4561 21.2148L16.9688 12.7285L25.4551 4.24219Z"
        fill={props.color || "black"}
        fill-opacity={props.opacity || 1}
      />
    </svg>
  );
}
