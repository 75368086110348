import React from "react";

export default function() {
  return (
    <svg
      width="14"
      height="20"
      viewBox="0 0 14 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 0C3.13 0 0 3.13 0 7C0 12.25 7 20 7 20C7 20 14 12.25 14 7C14 3.13 10.87 0 7 0ZM7 9.5C5.62 9.5 4.5 8.38 4.5 7C4.5 5.62 5.62 4.5 7 4.5C8.38 4.5 9.5 5.62 9.5 7C9.5 8.38 8.38 9.5 7 9.5Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x2="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(14) scale(14 20) rotate(90)"
        >
          <stop stop-color="#be342d" stop-opacity="0.97" />
          <stop offset="1" stop-color="#e82751" stop-opacity="0.97" />
        </linearGradient>
      </defs>
    </svg>
  );
}
