import React from "react";
import logo from "../assets/logo.svg";
import menu from "../assets/menu.svg";

import Cross from "./cross.js";
import index from "./algolia";

export default class Header extends React.PureComponent {
  state = {
    clearButtonIsHovered: false,
    showClearButton: false,
    searchFocused: false,
    suggestions: [],
    searchPhrase: ""
  };

  hoverClearButton = bool => () =>
    this.setState({ clearButtonIsHovered: bool });

  focus = bool => event => this.setState({ searchFocused: bool });

  findSuggestions = () => {
    /*   this.setState({
      suggestions: ["Ruchaj psa", "Jak sra"]
    }); */

    if (this.state.searchPhrase === "") return;

    let params = {
      hitsPerPage: "3",
      page: "0",
      attributesToRetrieve: [],
      attributesToHighlight: [
        "name",
        "offer",
        "shortDescription",
        "city",
        "street"
      ]
    };
    index.search(this.state.searchPhrase, params, (err, content) =>
      err
        ? console.error(err.message)
        : content.query === this.state.searchPhrase
        ? this.setState({
            suggestions: possibleWords(content.hits)
          })
        : null
    );
  };

  render() {
    const tabs = this.props.tabs;
    return (
      <div
        style={
          this.props.dontShowSearchBar
            ? { height: 470, overflow: "hidden" }
            : {}
        }
        className="header"
      >
        <div className="header__block">
          <img
            onClick={() => this.select.focus()}
            style={{ height: 40 }}
            src={logo}
          />
          {window.innerWidth < 700 ? (
            <React.Fragment>
              <select
                style={{
                  opacity: 0,
                  width: "100%"
                }}
                ref={ref => (this.select = ref)}
                onChange={event => {
                  if (event.nativeEvent.target.value !== "-")
                    this.props.changeTab(event.nativeEvent.target.value);
                }}
              >
                {tabs.map((tabName, i) => (
                  <option key={tabName} value={i}>
                    {tabName}
                  </option>
                ))}
              </select>
              <img
                style={{
                  height: 18,
                  pointerEvents: "none",
                  position: "absolute",
                  top: 426,
                  right: 18
                }}
                src={menu}
              />
            </React.Fragment>
          ) : (
            <div className="block__nav">
              {tabs.map((tabName, i) => (
                <a
                  key={tabName}
                  onClick={() => {
                    this.props.changeTab(i);
                  }}
                  style={
                    i === this.props.pickedTab
                      ? {
                          color: "rgb(26, 162, 255)"
                        }
                      : {}
                  }
                  className="nav__item"
                >
                  {tabName}
                </a>
              ))}
            </div>
          )}
        </div>
        <input
          onFocus={this.focus(true)}
          onKeyPress={e => {
            if (e.key !== "Enter") {
              this.focus(true)();
              return;
            }
            this.props.search(e.target.value);
            this.focus(false)();
          }}
          onChange={event => {
            this.setState(
              { searchPhrase: event.target.value.trim() },
              this.findSuggestions
            );
            if (event.target.value === "") {
              this.setState({ showClearButton: false });
              this.props.search(``);
            } else {
              this.setState({ showClearButton: true });
            }
          }}
          ref={ref => {
            this.input = ref;
          }}
          className="header__search-input"
          placeholder="Szukaj..."
        />
        {this.state.showClearButton && this.props.dontShowSearchBar === false && (
          <div
            onMouseOver={this.hoverClearButton(true)}
            onMouseLeave={this.hoverClearButton(false)}
            onClick={() => {
              this.input.value = ``;
              this.setState({ searchPhrase: "", showClearButton: false });
              this.props.search(``);
            }}
            className="header__clear-button"
          >
            <Cross
              size={12}
              color={`rgba(255,255,255,${
                this.state.clearButtonIsHovered ? 1 : 0.5
              })`}
            />
          </div>
        )}
        {this.state.searchFocused && !this.props.dontShowSearchBar
          ? this.state.searchPhrase !== "" && (
              <div className="header__suggestions">
                {[
                  ...new Set([
                    this.state.searchPhrase,
                    ...this.state.suggestions
                  ])
                ].map(e => (
                  <div
                    onClick={event => {
                      this.input.value = e;
                      this.props.search(e);
                      this.focus(false)();
                    }}
                    className="suggestions__suggestion"
                  >
                    {e}
                  </div>
                ))}
              </div>
            )
          : null}

        {navigator.vendor == "Apple Computer, Inc." &&
        window.innerWidth < 700 ? (
          <div
            style={{
              backgroundColor: "#2d3f50",
              width: "100vw",
              height: 8,
              position: "fixed",
              top: 57,
              left: 0
            }}
          />
        ) : null}
        {navigator.userAgent.toLowerCase().indexOf("firefox") > -1 &&
        window.innerWidth < 700 ? (
          <div
            style={{
              backgroundColor: "#2d3f50",
              width: "100vw",

              zIndex: 5,
              height: 16,
              position: "fixed",
              top: 53,
              left: 0
            }}
          />
        ) : null}
      </div>
    );
  }
}

function possibleWords(hits) {
  try {
    return [
      ...new Set(
        JSON.stringify(hits)
          .split(`<em>`)
          .map((e, i) =>
            i === 0
              ? null
              : e
                  .split(`</em>`)
                  .join("")
                  .split(",")[0]
                  .toLowerCase()
                  .trim()
                  .split(`"`)
                  .join("")
                  .split(`\n`)[0]
                  .split(" ")
                  .slice(0, 3)
                  .join(" ")
          )
          .filter(e => Boolean(e))
      )
    ].slice(0, 3);
  } catch (e) {
    return [];
  }
}
