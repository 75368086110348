import React from "react";
import textParser from "./textParser";
import Gallery from "./gallery";

export default class OurPage extends React.Component {
  render() {
    return (
      <div className="our-page">
        <article className="our-page__paragraph">
          <h1> {this.props.data.name}</h1>
          {textParser(this.props.data.content)}
          <Gallery
            style={{
              backgroundColor: "rgba(0,0,0,0.015)",
              padding: 9,
              borderRadius: 3
            }}
            pics={this.props.photos}
          />
        </article>
      </div>
    );
  }
}
