import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./src/app";
import Modal from "./src/modalPage";
import "./styles/index.scss";

let pathArray = window.location.hostname.split(`.`);

let subdomain = pathArray[0] === "www" ? pathArray[1] : pathArray[0];

ReactDOM.render(
  subdomain !== "autoinfo" && subdomain !== "localhost" ? (
    <Modal standalone={subdomain} />
  ) : (
    <App />
  ),
  document.getElementById("root")
);

// Hot Module Replacement
if (module.hot) {
  module.hot.accept();
}
