import React, { Component } from "react";

export default class Company extends React.PureComponent {
  state = {
    active: false
  };

  render() {
    const { props } = this;

    const company = props.company;
    const isSponsor = company.sponsorship === 1;
    return (
      <a
        onClick={e => {
          props.onPick(company);
          e.preventDefault();
        }}
        onMouseOver={() => this.setState({ active: true })}
        onMouseLeave={() => this.setState({ active: false })}
        className="results__tile"
      >
        <div
          style={
            company.sponsorship === 1
              ? { border: "2px solid rgb(238, 138, 44)" }
              : {}
          }
          className="tile__container"
        >
          {company.img && <img className="container__img" src={company.img} />}
        </div>
        <h2
          className="tile__name"
          style={{
            color: isSponsor
              ? "rgb(238, 138, 44)"
              : this.state.active
              ? "rgb(26, 162, 255)"
              : "#131313"
          }}
        >
          {company.name}
        </h2>
        <h3
          className="tile__description"
          style={{
            color: isSponsor
              ? "rgb(238, 138, 44)"
              : this.state.active
              ? "rgb(26, 162, 255)"
              : "gray"
          }}
        >
          {company.shortDescription}
        </h3>
      </a>
    );
  }
}
